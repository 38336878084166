<template>
  <!-- 选择发布类型 -->
  <div class="contentBox">
    <stepOne ref="first"></stepOne>
    <section>
      <div class="btnBox">
        <div class="imgText clearfix" @click="goPostOne">
          <div>
            <img :src="iconleft" alt="" />
          </div>
          <div class="text">
            <span>发布职位</span><br />
            <span>发布招聘信息</span>
          </div>
        </div>
        <div class="imgText clearfix" @click="goPostTwo">
          <div>
            <img :src="iconright" alt="" />
          </div>
          <div class="text">
            <span>发布求职</span><br />
            <span>发布求职信息</span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import stepOne from "@/components/stepOne/stepOne";
export default {
  components: {
    stepOne,
  },
  data() {
    return {
      iconleft: require("@/assets/img/post01.png"),
      iconright: require("@/assets/img/post02.png"),
    };
  },
  methods: {
    goPostOne() {
      this.$router.push("postOne");
    },
    goPostTwo() {
      this.$router.push("postTwo");
    },
  },
};
</script>
<style scoped>
.btnBox {
  padding: 100px;
  text-align: center;
}
.btnBox .imgText:first-child {
  margin-right: 36px;
}
.imgText {
  width: 320px;
  padding: 20px 20px 15px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  display: inline-block;
}
.imgText:hover {
  background: #eef9fd;
  cursor: pointer;
}
.imgText div {
  float: left;
}
.imgText > div:first-child {
  width: 55px;
}
.imgText .text {
  padding-left: 80px;
  padding-top: 30px;
  line-height: 50px;
}
.padding-20 {
  padding: 20px;
}
.imgText .text span:first-child {
  font-size: 32px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
  display: inline-block;
  padding: 9px;
  padding-top: 0;
}
.imgText .text span:last-child {
  font-size: 18px;
  color: #999999;
  line-height: 24px;
  display: inline-block;
  padding-left: 9px;
}
</style>

<template>
  <!-- 步骤一 -->
  <div class="contentBox">
    <section>
      <div class="text-center">
        <img :src="topImg" alt="" />
      </div>
      <el-steps :active="0" align-center class="stepsBox">
        <el-step title="选择类型"></el-step>
        <el-step title="填写信息"></el-step>
        <el-step title="完成发布"></el-step>
      </el-steps>
    </section>
  </div>
</template>
<script>
export default {
  name: "stepOne",
  data() {
    return {
      topImg: require("@/assets/img/post03.png"),
    };
  },
};
</script>
<style scoped></style>
